//Body Styles
body {
  font-family: $bodyFontFamily;
  font-size: $bodyFontSize;
  color: $bodyFontColor;
  font-weight: $bodyFontWeight;
  background:$bodyBgColor;
  line-height: $bodyLineHeight;
}

a {
  transition:all 0.25s ease-out;
  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

.gradient {
  background: #4a8db5; /* Old browsers */
  background: -moz-linear-gradient(45deg,  #4a8db5 0%, #20af5f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg,  #4a8db5 0%,#20af5f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg,  #4a8db5 0%,#20af5f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4a8db5', endColorstr='#20af5f',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.wow {
  visibility: hidden;
}

section.content {
  background: white;
  padding:50px;
  &.pad100 {
    padding:40px;
  }
  &.pad150 {
    padding:40px;
  }
  &.gray {
    background: none;
  }
}

p {
  margin-bottom:30px;
}

@media(min-width:768px) {
  section.content {
    background: white;
    padding:50px;
    &.pad100 {
      padding:100px;
    }
    &.pad150 {
      padding:150px;
    }
    &.gray {
      background: none;
    }
  }
}
