footer {
  background: #4A4A4A;
  padding:25px;
  text-align: center;
  color:white;
  div.f-left {
    margin-bottom:20px;
  }
}

@media(min-width:768px) {
  footer {
    text-align: left;
    div.f-left {
      margin-bottom:0px;
      float: left;
    }
    div.f-right {
      float: right;
    }
  }
}
