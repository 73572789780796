section.screenshot {
  position: relative;
  text-align: center;
  padding: 40px;
  img {
    display: inline-block;
  }
}

div.about-icon {
  text-align: center;
  img {
    display: inline-block;
    margin-bottom: 20px;
  }
}

span.section-heading {
  display: block;
  color: $green;
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 40px;
}

section.features {
  display: block;
  div.ind-feature {
    border-top: 9px solid $blue;
    padding: 40px;
    text-align: center;
    width: auto;
    float: none;
    img {
      display: inline-block;
      margin-bottom: 30px;
      filter: grayscale(160%);
    }
    &.blue {
      border-top-color: $black;
      background: white;
      span.section-heading {
        color: $green;
      }
    }
    &.red {
      border-top-color: $black;
      span.section-heading {
        color: $green;
      }
    }
    &.green {
      border-top-color: $black;
      background: white;
      span.section-heading {
        color: $green;
      }
    }
    &.orange {
      border-top-color: $black;
      background: none;
      span.section-heading {
        color: $green;
      }
    }
  }
}

//Step one of registration, choose your package...
div.ind-plan {
  text-align: center;
  border-top: 1px solid #afafaf;
  transition: all 0.25s ease;
  &:hover {
    background: rgba(white, 0.25);
  }
  div.ip-name {
    font-weight: 300;
    font-size: 25px;
    color: $green;
    margin-bottom: 20px;
    padding: 20px 10px;
  }
  div.ip-price {
    font-size: 45px;
    color: black;
    text-align: center;
    margin-bottom: 20px;
    span {
      display: inline-block;
      font-size: 14px;
      font-weight: 300;
    }
  }
  div.ip-users {
    padding: 10px;
    background: #fafafa;
    font-size: 20px;
    font-weight: 400;
    color: lighten(#000, 25%);
  }
  a.ip-btn {
    display: block;
    padding: 10px;
    color: white;
    background: $green;
    font-size: 19px;
    &:hover {
      background: lighten(saturate($green, 10%), 10%);
      color: white;
    }
  }
}

div.modal-overlay {
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.8);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    div.cs-modal {
      top: 50px;
      opacity: 1;
      visibility: visible;
    }
  }
  div.cs-modal {
    background: white;
    position: absolute;
    z-index: 20;
    width: 90%;
    max-width: 1000px;
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-out;
  }
  div.cs-modal-content {
    padding: 50px;
  }
}

span.emailError {
  display: inline-block;
  font-size: 14px;
  font-style: italic;
  color: red;
  font-weight: 300;
  padding: 8px 25px;
  background: #f5dede;
  margin-top: 5px;
}

div.plan-text {
  text-align: center;
  padding-top: 40px;
}

div.early-signup {
  padding-bottom: 40px;
}

div.chosen-plan {
  background: $green;
  border-left: 6px solid lighten($green, 5%);
  padding: 25px;
  color: white;
  font-size: 17px;
  margin-bottom: 40px;
  a {
    float: right;
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    color: lighten($green, 75%);
  }
}

input.radio-input {
  position: relative;
  top: -4px;
  margin-right: 10px;
}

div.select-discount {
  padding-bottom: 10px;
  margin-bottom: 40px;
  border-bottom: 1px solid #ebebeb;
}

div.loading-indicator {
  position: absolute;
  z-index: 1500;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.8);
  color: $green;
  div.li-spin {
    padding: 40px;
    background: white;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }
}

@media (min-width: 768px) {
  section.screenshot {
    padding: 0px 0px 50px 0px;
  }
  section.features {
    display: flex;
    flex-flow: wrap;
    div.ind-feature {
      float: left;
      width: 50%;
      img {
        filter: grayscale(160%);
      }
      &.blue {
        border-top-color: $black;
        background: white;
        span.section-heading {
          color: $green;
        }
      }
      &.red {
        border-top-color: $black;
        span.section-heading {
          color: $green;
        }
      }
      &.green {
        border-top-color: $black;
        background: white;
        span.section-heading {
          color: $green;
        }
      }
      &.orange {
        border-top-color: $black;
        background: none;
        span.section-heading {
          color: $green;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  section.screenshot {
    padding: 0px 0px 50px 0px;
  }
}

@media (min-width: 1690px) {
  section.screenshot {
    display: none;
  }
  section.features {
    div.ind-feature {
      float: left;
      width: 25%;
      img {
        filter: grayscale(160%);
      }
      &.blue {
        border-top-color: $black;
        background: white;
        span.section-heading {
          color: $green;
        }
      }
      &.red {
        border-top-color: $black;
        span.section-heading {
          color: $green;
        }
      }
      &.green {
        border-top-color: $black;
        background: white;
        span.section-heading {
          color: $green;
        }
      }
      &.orange {
        border-top-color: $black;
        background: none;
        span.section-heading {
          color: $green;
        }
      }
    }
  }
}
