@import url("https://fonts.googleapis.com/css?family=Lato:100,300,300i,400,400i,700,700i");

//Body Styles
$bodyFontFamily: "Lato";
$bodyFontSize: 20px;
$bodyLineHeight: 35px;
$bodyFontWeight: 300;
$bodyFontColor: #595959;
$bodyBgColor: #f1f1f1;

//Colors
$orange: #ee6c4d;
$green: #20af5f;
$blue: #4a8db5;
$red: #d0555b;
$black: #282828;
