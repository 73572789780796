//header
div.color-bars {
  overflow: auto;
  div {
    height:8px;
    float: left;
    width:25%;
    &.blue {
      background: $blue;
    }
    &.green {
      background: $green;
    }
    &.orange {
      background: $orange;
    }
    &.red {
      background: $red;
    }
  }
}

.triangle-mask {
  width: 100%;
  height: auto;
  border-bottom: 129px solid #F1F1F1;
  border-left: 5000px solid transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
}

header.main-header {
  background: url(../img/hero-bg.png) no-repeat center center transparent;
  background-size:cover;
  padding:40px;
  min-height: 850px;
  position: relative;
  z-index: 0;
  div.flex {
    display: flex;
    color:white;
    div {
      float: left;
      &.flex-left {
        width:100%;
        a.logo {
          display: block;
          margin-bottom:60px;
          text-align: center;
          img {
            display: inline-block;
          }
        }
        span.subheader {
          display: block;
          text-transform: uppercase;
          font-size: 15px;
          margin-bottom:10px;
          font-weight: 100;
        }
        h1 {
          font-size: 20px;
          line-height: 32px;
          font-weight: 300;
          display: block;
          margin: 0px 0px 50px 0px;
          max-width:100%;
          em {
            font-weight: 400;
            font-style: italic;
          }
        }
        div.input-group {
          display: inline-block;
          input.form-control {
            background: white;
            border:none;
            border-radius:0px;
            height:auto;
            font-size: 20px;
            font-style: italic;
            font-weight: 400;
            padding:10px;
            min-width:100%;
            max-width:100%;
          }
          button.btn-default {
            background: $green;
            border: none;
            color: white;
            font-size: 20px;
            font-weight: 300;
            padding: 15px 36px;
            min-width:100%;
            transition:all 0.25s ease-out;
            &:hover {
              background: lighten(saturate($green, 5%), 5%);
            }
          }
        }
        span.helper-text {
          display: block;
          margin:20px 0px 50px 0px;
          font-weight: 300;
          font-size: 17px;
        }
      }
      &.flex-right {
        width:0%;
        text-align: center;
        visibility: hidden;
        img {
          display: inline-block;
        }
      }
    }
  }
}

@media(min-width:768px) {
  div.triangle-mask {
    border-bottom-width:177px;
  }
  header.main-header {
    background: url(../img/hero-bg.png) no-repeat center center transparent;
    background-size:cover;
    padding:40px;
    min-height: 850px;
    div.flex {
      display: flex;
      color:white;
      div {
        float: left;
        &.flex-left {
          width:100%;
          a.logo {
            display: block;
            margin-bottom:100px;
            text-align: center;
            img {
              display: inline-block;
            }
          }
          span.subheader {
            display: block;
            text-transform: uppercase;
            font-size: 20px;
            margin-bottom:10px;
            font-weight: 100;
          }
          h1 {
            font-size: 32px;
            line-height: 48px;
            font-weight: 300;
            display: block;
            margin: 0px 0px 100px 0px;
            max-width:650px;
            em {
              font-weight: 400;
              font-style: italic;
            }
          }
          div.input-group {
            display: inline-block;
            input.form-control {
              background: white;
              border:none;
              border-radius:0px;
              height:auto;
              font-size: 25px;
              font-style: italic;
              font-weight: 400;
              padding:15px;
              min-width:350px;
              max-width:350px;
            }
            button.btn-default {
              background: $green;
              border: none;
              color: white;
              font-size: 25px;
              font-weight: 300;
              padding: 15px 36px;
              width:auto;
              min-width:100px;
              max-width:auto;
            }
          }
          span.helper-text {
            display: block;
            margin:20px 0px 50px 0px;
            font-weight: 300;
            font-size: 17px;
          }
        }
        &.flex-right {
          width:0%;
          text-align: center;
          visibility: hidden;
          img {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media(min-width:992px) {
  header.main-header {
    background: url(../img/hero-bg.png) no-repeat center center transparent;
    background-size:cover;
    padding:50px;
    min-height: 850px;
    div.flex {
      display: flex;
      color:white;
      div {
        float: left;
        &.flex-left {
          width:100%;
          a.logo {
            display: block;
            margin-bottom:100px;
            text-align: left;
            img {
              display: inline-block;
            }
          }
          span.subheader {
            display: block;
            text-transform: uppercase;
            font-size: 20px;
            margin-bottom:10px;
            font-weight: 100;
          }
          h1 {
            font-size: 32px;
            line-height: 48px;
            font-weight: 300;
            display: block;
            margin: 0px 0px 100px 0px;
            max-width:650px;
            em {
              font-weight: 400;
              font-style: italic;
            }
          }
          div.input-group {
            display: inline-block;
            input.form-control {
              background: white;
              border:none;
              border-radius:0px;
              height:auto;
              font-size: 25px;
              font-style: italic;
              font-weight: 400;
              padding:15px;
              min-width:350px;
              max-width:350px;
            }
            button.btn-default {
              background: $green;
              border: none;
              color: white;
              font-size: 25px;
              font-weight: 300;
              padding: 15px 36px;
              width:auto;
              min-width:100px;
              max-width:auto;
            }
          }
          span.helper-text {
            display: block;
            margin:20px 0px 50px 0px;
            font-weight: 300;
            font-size: 17px;
          }
        }
        &.flex-right {
          width:0%;
          text-align: center;
          visibility: hidden;
          img {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media(min-width:1690px) {
  div.triangle-mask {
    border-bottom-width: 280px;
  }
  header.main-header {
    padding:40px;
    position: relative;
    z-index: 0;
    div.flex {
      display: flex;
      color:white;
      position: relative;
      z-index: 20;
      div {
        float: left;
        &.flex-left {
          width:50%;
          a.logo {
            display: block;
            margin-bottom:100px;
          }
          span.subheader {
            display: block;
            text-transform: uppercase;
            font-size: 20px;
            margin-bottom:10px;
            font-weight: 100;
          }
          h1 {
            font-size: 32px;
            line-height: 48px;
            font-weight: 300;
            display: block;
            margin: 0px 0px 60px 0px;
            max-width:650px;
            em {
              font-weight: 400;
              font-style: italic;
            }
          }
          div.input-group {
            input.form-control {
              background: white;
              border:none;
              border-radius:0px;
              height:auto;
              font-size: 32px;
              font-style: italic;
              font-weight: 400;
              padding:15px;
              min-width:500px;
            }
            button.btn-default {
              background: $green;
              border: none;
              color: white;
              font-size: 32px;
              font-weight: 300;
              padding: 15px 36px;
            }
          }
          span.helper-text {
            display: block;
            margin:20px 0px 50px 0px;
            font-weight: 300;
            font-size: 17px;
          }
        }
        &.flex-right {
          width:50%;
          text-align: center;
          visibility: visible;
          img {
            display: inline-block;
          }
        }
      }
    }
  }
}
