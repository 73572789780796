div.form-group {
  label {
    span {
      color:red;
    }
  }
  input.form-control {
    padding:8px;
    border-radius:0px;
    border-top:none;
    border-left:none;
    border-right:none;
    border-bottom:1px solid black;
    height:auto;
    box-shadow: none;
    transition:all 0.25s ease-out;
    font-size: 17px;
    &:focus {
      border-bottom:1px solid lighten($blue, 20%);
    }
  }
  select.form-control {
    padding:8px;
    border-radius:0px;
    border-top:none;
    border-left:none;
    border-right:none;
    border-bottom:1px solid black;
    height:auto;
    box-shadow: none;
    transition:all 0.25s ease-out;
    font-size: 17px;
    appearance:none;
    -webkit-appearance:none;
    -moz-appearance:none;
    background: url(../img/down-arrow.png) no-repeat right 10px center white;
    &:focus {
      border-bottom:1px solid lighten($blue, 20%);
    }
  }
}

span.f-error {
  color:red;
  display: block;
  font-size: 15px;
  font-weight: 400;
}

.btn-primary {
  background: $green;
  border-radius:0px;
  color:white;
  border:none;
  transition:all 0.25s ease-out;
  &:hover, &:focus {
    background: lighten($green, 10%);
    color:white;
  }
  &.btn-lg {
    padding:20px;
    font-size: 20px;
    font-weight: 400;
  }
  &.btn-form {
    margin-top:40px;
  }
}

div.payment-status {
  padding:10px;
  color:white;
  font-size: 16px;
  font-weight:400;
  &.success {
    background: lighten($green, 5%);
  }
  &.failure {
    background: lighten($red, 5%);
  }
}
